import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import LoadingTopBar from "components/LoadingComponent/LoadingTopBar";
import ToastMobileComponent from "components/ToastMobileComponent/ToastMobileComponent";
import { addRefreshToken, checkAuth, logout } from "features/authSlice";
import { addListMenuTrees, setShowToastGlobal } from "features/commonSlice";
import {
  addIsMiniDesktop,
  addIsMobile,
  addIsTablet,
  addThemeMode,
} from "features/layoutSlice";
import {
  addListGames,
  addListStatus,
  addListStatusTransaction,
} from "features/lotterySlice";
import { showAlertMinigame } from "features/minigamesSlice";
import { addBalanceUser, addUserInfo } from "features/userSlice";
import useToast from "hooks/useToast";
import { MenuItem } from "interfaces/common.interface";
import MainLayout from "layout/MainLayout";
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { mainRoutes } from "./routes/routes";

function App() {
  const dispatch = useDispatch();
  const toastify = useToast();
  const navigate = useNavigate();
  const pathname = useLocation();
  const { mode, showSidebar, isMobile } = useSelector(
    (state: any) => state.layout
  );
  const { token } = useSelector((state: any) => state.auth);
  const { loading, loadingTopBar, toastGlobal } = useSelector(
    (state: any) => state.common
  );

  const { configSeo } = useSelector((state: any) => state.seo);

  useEffect(() => {
    if (toastGlobal && toastGlobal?.show === true) {
      toastify(toastGlobal?.msg, toastGlobal?.type);
      setTimeout(() => {
        dispatch(setShowToastGlobal({ show: false, msg: null, type: null }));
      }, 5000);
    }
  }, [toastGlobal]);
  const { alertMinigame } = useSelector((state: any) => state.minigames);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1280 && screenWidth < 1440) {
        dispatch(addIsMiniDesktop(true));
      } else {
        dispatch(addIsMiniDesktop(false));
      }

      if (screenWidth < 1280 && screenWidth > 675) {
        dispatch(addIsTablet(true));
      } else {
        dispatch(addIsTablet(false));
      }

      if (screenWidth <= 675) {
        dispatch(addIsMobile(true));
      } else {
        dispatch(addIsMobile(false));
      }
    };

    const darkModeLocal = localStorage.getItem("darkMode");
    if (!darkModeLocal) {
      localStorage.setItem("darkMode", "false");
    }
    if (darkModeLocal === "true") {
      dispatch(addThemeMode(1));
    } else {
      dispatch(addThemeMode(0));
    }

    handleGetListGames();

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (showSidebar && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showSidebar, isMobile]);

  const handleGetListGames = async () => {
    try {
      const { data } = await lotteryApi.getListGames();
      if (data) {
        dispatch(addListGames(data.data));
      }
    } catch (error) {
      return;
    }
  };

  return (
    <div className={classNames("font-inter w-full", { dark: mode === 1 })}>
      {/* {!isAuth && <SplashScreen />} */}

      <Helmet>
        <title>{configSeo?.title}</title>
        <meta name="description" content={configSeo?.description} />
        <meta name="keywords" content={configSeo?.keywords} />
        <meta property="og:title" content={configSeo?.title} />
        <meta property="og:description" content={configSeo?.description} />
        <meta property="og:image" content={configSeo?.image} />
        <meta property="og:url" content={configSeo?.url} />
      </Helmet>

      <Routes>
        {mainRoutes &&
          mainRoutes?.length > 0 &&
          mainRoutes?.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <MainLayout>
                    <Page />
                  </MainLayout>
                }
              />
            );
          })}

        <Route path="*" element={<Navigate to={"/ket-qua"} replace />} />
      </Routes>
      <ToastContainer
        position={"top-right"}
        closeOnClick
        pauseOnFocusLoss
        autoClose={5000}
        hideProgressBar={false}
        limit={3}
        draggable={true}
        theme={mode === 1 ? "dark" : "light"}
        style={{ width: "320px" }}
      />
      {loading && <LoadingComponent />}
      <LoadingTopBar loading={loadingTopBar} />
      <ToastMobileComponent position="top" closeOnClick autoClose={5000} />
    </div>
  );
}

export default App;
