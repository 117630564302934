import authApi from "apis/authApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { listSocial } from "constant";
import { addRefreshToken, checkAuth, logout } from "features/authSlice";
import { addThemeMode } from "features/layoutSlice";
import { addBalanceUser, addUserInfo } from "features/userSlice";
import useToast from "hooks/useToast";
import moment from "moment";
import "moment/locale/vi";
import MenuMobile from "partials/MenuMobile/MenuMobile";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleRedirectLink } from "utils/commonFormat";
import { icons } from "utils/icons";
import "./Header.scss";

moment.locale("vi");

function Header() {
  const pathname = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastify = useToast();
  const popopverUserRef = useRef<any>();
  const menuMobileRef = useRef<any>();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { isAuth } = useSelector((state: any) => state.auth);
  const { countInbox } = useSelector((state: any) => state.common);
  const { userInfo, balance } = useSelector((state: any) => state.user);
  const { isTablet, isMobile, mode } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (menuMobileRef.current && !menuMobileRef.current.contains(e.target)) {
        setShowMenuMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (showMenuMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenuMobile]);

  const handleNavigate = (path: any, isPublic?: boolean) => {
    if (!isAuth) {
      if (isPublic === true) {
        navigate(path);
      } else {
        toastify("Vui lòng đăng nhập!", "warning");
        return;
      }
    } else {
      navigate(path);
    }
    handleClosePopover();
    setShowMenuMobile(false);
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  return (
    <div
      className="w-full flex items-center justify-center shadow-sm bg-black"
      ref={menuRef}
    >
      {showPopover && (
        <div
          className="w-full h-full fixed top-0 left-0 z-10"
          onClick={handleClosePopover}
        ></div>
      )}
      <header
        className={`header w-full flex justify-center items-center max-w-[1440px] ${
          !isMobile ? "px-3" : ""
        }`}
      >
        {!isMobile ? (
          <div className="flex flex-col justify-center items-center w-full h-[84px] py-2">
            {/* {type !== "landing" && ( */}
            <div className="flex items-center justify-between w-full mb-2">
              <div className="flex items-center text-xs text-white/40 font-semibold">
                <span
                  className="py-0.5 px-2 hover:text-white cursor-pointer"
                  onClick={() => handleNavigate("/ve-chung-toi", true)}
                >
                  Về chúng tôi
                </span>
                <span
                  className="py-0.5 px-2 hover:text-white cursor-pointer"
                  onClick={() =>
                    handleRedirectLink("https://forms.office.com/r/xmKmtrUCZ4")
                  }
                >
                  Liên hệ quảng cáo
                </span>
              </div>
              <div className="flex items-center">
                {listSocial.map((social: any, index: number) => (
                  <div
                    className="w-4 h-4 group ml-2 cursor-pointer"
                    key={index}
                  >
                    <img
                      src={social.iconDefault}
                      className="w-full h-full block group-hover:hidden"
                      alt={configSeo?.title}
                    />
                    <img
                      src={social.iconHover}
                      className="w-full h-full hidden group-hover:block"
                      alt={configSeo?.title}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}

            <div className="header-container w-full sm:hidden block">
              <div className="topbar flex justify-between items-center w-full">
                <div className="header-left flex items-center">
                  <img
                    src={require("assets/images/logo.png")}
                    className={`${
                      isTablet ? "h-9" : "h-7"
                    } w-auto cursor-pointer`}
                    onClick={() => navigate("/")}
                    alt={configSeo?.title}
                  />
                </div>

                <div className="header-right flex items-center">
                  <button
                    className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 outline-none rounded-[10px] ml-2"
                    type="button"
                    onClick={handleToggleMode}
                  >
                    <img
                      src={mode === 1 ? icons.sun : icons.moon}
                      className="icon-header text-primary w-5 h-5"
                      alt={configSeo?.title}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="header-mobile h-[56px] flex items-center w-full px-4 py-2 min-h-14 bg-bgLayout">
            <div className="w-full flex items-center justify-between">
              <div className="main-left flex items-center">
                {!pathname.pathname.includes("/ket-qua") && (
                  <div
                    className="wrap-user-info flex items-center justify-center bg-primary/10 rounded-[10px] cursor-pointer w-9 h-9 mr-2"
                    onClick={() => handleNavigate("/ket-qua", true)}
                  >
                    <img
                      src={icons.arrowLeft}
                      className="avatar w-4 h-4"
                      alt={configSeo?.title}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="w-10 h-10 flex items-center justify-center text-primary"
                  onClick={() => handleNavigate("/ket-qua", true)}
                >
                  <img
                    src={require("assets/images/icon-logo.png")}
                    className="h-10"
                    alt={configSeo?.title}
                  />
                </button>
              </div>
              <div className="main-right flex items-center">
                <div
                  className="wrap-user-info flex items-center justify-center bg-primary/10 rounded-[10px] cursor-pointer w-9 h-9"
                  onClick={() => setShowMenuMobile(true)}
                  ref={popopverUserRef}
                >
                  <img
                    src={icons.hamburger}
                    className="avatar w-5 h-5"
                    alt={configSeo?.title}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </header>

      <div className="z-[50]" ref={menuMobileRef}>
        <MenuMobile
          onClose={() => setShowMenuMobile(false)}
          isShow={showMenuMobile}
        />
      </div>
    </div>
  );
}

export default Header;
